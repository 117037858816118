.slick-dots {
  position: relative;
  bottom: 0;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button::before {
  color: #e0e0e0;
  opacity: 1;
  font-size: 10px;
}

.slick-dots li.slick-active button::before {
  color: #3367cd;
  opacity: 1;
}
